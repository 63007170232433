import React from "react";

import {
  Button
  // Customizable Area Start

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { connect } from "react-redux";
import { logoImage } from "./assets";
import "../assets/css/PageOne.web.css";
import { setInitialQuestionsAction, updateQuestionsAction,updateStepAction ,updateSkipppedQuestionIdsAction} from "../../../framework/src/Redux/Actoins/QuestionActions";


// Customizable Area End

import MultiFormsController, {
  Props,
} from "./MultiFormsController";
import MultiPageFormHelper from "./MutiPageFormHelper.web";
import CustomSlider from "./CustomSlider.web";

export class Multipageforms2 extends MultiFormsController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getWrapperClass(showBackButton: boolean, response_type: string, genderTitle: number) {
    if (response_type === "body_type" && genderTitle === 12) {
      return "common-wrapper mf-bodyque ";
    }
    if (!showBackButton) {
      return 'common-wrapper-termsconditions';
    }

    return 'common-wrapper';
  }
  // Customizable Area End

  render() {
  
    const { questions, updateQuestionsAction, showBackButton,updateSkipppedQuestionIdsAction ,skippedQuestionsIds,reduxStep} = this.props;
    const response_type = this.getResponseTypeFromData(questions, reduxStep);
    const filteredData = questions.filter((item:any) => item.attributes.question_id === 3 );
    const selectedGender = filteredData[0]?.attributes.options.find((option: any) => option.is_selected === true)
    return (
      // Customizable Area Start
      <div className={this.getWrapperClass(showBackButton, response_type, selectedGender?.id)}>
        <img src={logoImage} alt="" className="logo-img" width={115} />
          {(response_type != "static" && response_type != "last_image" && response_type != "weight_graph" && showBackButton) && <CustomSlider QuestionLength={questions.length} step={this.props.reduxStep} goBack={this.goBack}/>}
        {questions.length > 0 && <MultiPageFormHelper questions={questions} step={this.props.reduxStep} responceType={response_type} 
        updateQuestionsAction={updateQuestionsAction} setStepFunc={this.setStepFunc}
        target_month={this.state.target_month} 
        target_weight={this.state.target_weight}
         weight_loss_month={this.state.weight_loss_month}
          weight_loss_data={this.state.weight_loss_data}
          weight_unit={this.state.weight_unit}
          skippedQuestions={skippedQuestionsIds}
          updateSkipppedQuestionIdsAction={updateSkipppedQuestionIdsAction}
           />}
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const mapStateToProps = (state: any) => {
  return {
    questions: state.QuestionReducer.questions,
    reduxStep: state.QuestionReducer.reduxStep,
    showBackButton: state.QuestionReducer.showBackButton,
    skippedQuestionsIds: state.QuestionReducer.skippedQuestionsIds,
  }
  // map your state properties here
};
function mapDispatchToProps(dispatch: any) {
  return {
    updateQuestionsAction: (questions: any) => dispatch(updateQuestionsAction(questions)),
    setInitialQuestionsAction: (questions: any) => dispatch(setInitialQuestionsAction(questions)),
    updateStepAction: (step: any) => dispatch(updateStepAction(step)),
    updateSkipppedQuestionIdsAction: (questionIds: any) => dispatch(updateSkipppedQuestionIdsAction(questionIds))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Multipageforms2)
const webStyle = {
  mainWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "10px 0px",
    margin: "30px 30px"
  },

};
// Customizable Area End
