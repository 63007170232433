import React from 'react'
import { Button, FormControlLabel, Checkbox } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { updateSkipppedQuestionIdsAction } from '../../../framework/src/Redux/Actoins/QuestionActions';
interface Props {
    questions: any[];
    step: number;
    attributes: any;
    setStepFunc: (step: number) => void;
    updateQuestionsAction: (questions: any[]) => void;
}

export default function MealList ({ questions, step, attributes, setStepFunc, updateQuestionsAction }: Props) {
    const dispatch = useDispatch();
    const skippedQuestions = useSelector((store: any) => store.QuestionReducer.skippedQuestionsIds);
    const updateSkippedQuestions = () => {
        dispatch(updateSkipppedQuestionIdsAction([...skippedQuestions, attributes.question_id]));
    }
    const {  options } = attributes;

    
    const onUpdateQuestions = (index: number, item: any) => {
        let newOptions = [...options];
        let selectedOption = newOptions[index];

        newOptions[index] = { ...selectedOption, is_selected: !selectedOption.is_selected };
        let newQuestion = { ...questions[step], attributes: { ...attributes, options: [...newOptions] } };

        let resetquestion = newQuestion.attributes.options.map((d: any) => {
            if (d.title == "None") {
                return (item.title !== "None") ? { ...d, is_selected: false } : d
            } else {
                return (item.title === "None") ? { ...d, is_selected: false } : d
            }

        })
        newQuestion.attributes.options = resetquestion;

        const copyQuestions = [...questions];
        copyQuestions[step] = newQuestion;
        updateQuestionsAction([...copyQuestions]);
       
       
    }
    const filteredDataa = questions.filter((item: any) => item.attributes.question_id === 38);
    const goalObject = filteredDataa[0]?.attributes.options.find((option: any) => option.is_selected === true)
    const filteredDat = attributes.meal_types.filter((item: any) => item.id === goalObject?.id);
    if (!filteredDat.length) {
        updateSkippedQuestions();
        setStepFunc(1);
        return null;
     }
     
    const nextStep = () => {
        let x=localStorage.getItem("solveques")
        if(x){
            let pp=JSON.parse(x)
            pp.push(attributes.question_id)
            pp=JSON.stringify(pp)
            localStorage.setItem("solveques",pp)
        }else{
            let pp:any=[attributes.question_id]
            pp=JSON.stringify(pp)
            localStorage.setItem("solveques",pp)
        }
        setStepFunc(1)
        
          
    }


    return (
        <>
            <h1 className="page-title">{attributes.question}</h1>
            <div className="checkbox-options">
                {options.map((item: any, index: any) =>
                    <FormControlLabel
                        key={item.id}
                        data-test-id="option"
                        className={item.is_selected ? "option-box active" : "option-box"}
                        control={
                            <Checkbox
                                icon={<svg width={16} height={16} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.75" y="0.75" width="12.5" height="12.5" rx="1.25" fill="white" stroke="black" strokeWidth="1.5" /></svg>}
                                checkedIcon={<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                                <path d="M6 7.33268L8 9.33268L14.6667 2.66602" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14 8V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H10.6667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>}
                                checked={item.is_selected}
                                onClick={() => onUpdateQuestions(index, item)}
                            />
                        }
                        label={<>{item.title} <img src={item.image} alt="" height={24} /></>}
                    />
                )}
                <span className="options-note">*Select atleast 5 items</span>
                <Button
                    onClick={() => nextStep()}
                    data-test-id="NextButton"
                    className='btn-nextSecondary'
                    disabled={options.filter((option: any) => option.is_selected).length < 5}

                >Next</Button>
            </div>
        </>
    )
}
export const webStyles = {
    multiSelectWrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
    },
    multiNextButtonWrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginTop: "20px"

    },
    multiNextbutton: {
        fontSize: 16,
        padding: '6px 12px',
        borderRadius: "10px",
        lineHeight: 2,
        width: "100%",
        backgroundColor: '#0063cc',
        height: "56px"
    }
}