// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Dynamiccontent2 from "../../blocks/dynamiccontent2/src/Dynamiccontent2";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Apiint from "../../blocks/apiint/src/Apiint";
import Inapppurchasing from "../../blocks/inapppurchasing/src/Inapppurchasing";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Multiselect from "../../blocks/multiselect/src/Multiselect";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Notes2 from "../../blocks/notes2/src/Notes2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Imagemanagement from "../../blocks/imagemanagement/src/Imagemanagement";
import Settings2 from "../../blocks/settings2/src/Settings2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Videolibrary2 from "../../blocks/videolibrary2/src/Videolibrary2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Automaticreminders from "../../blocks/automaticreminders/src/Automaticreminders";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Videomanagement from "../../blocks/videomanagement/src/Videomanagement";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Multipageforms2 from "../../blocks/multipageforms2/src/Multipageforms2";
import Surveys2 from "../../blocks/surveys2/src/Surveys2";
import Survey from "../../blocks/surveys2/src/Multipageforms2.web";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import {store,persistor} from "../../framework/src/Redux/Store"
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'




const routeMap = {
Surveys2:{
 component:Surveys2,
path:"/Surveys2"},
Survey:{
  component:Survey,
 path:"/Survey"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},

Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Dynamiccontent2:{
 component:Dynamiccontent2,
path:"/Dynamiccontent2"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Apiint:{
 component:Apiint,
path:"/Apiint"},
Inapppurchasing:{
 component:Inapppurchasing,
path:"/Inapppurchasing"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Multiselect:{
 component:Multiselect,
path:"/Multiselect"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Notes2:{
 component:Notes2,
path:"/Notes2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Imagemanagement:{
 component:Imagemanagement,
path:"/Imagemanagement"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Videolibrary2:{
 component:Videolibrary2,
path:"/Videolibrary2"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Automaticreminders:{
 component:Automaticreminders,
path:"/Automaticreminders"},
Videos:{
 component:Videos,
path:"/Videos"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Videomanagement:{
 component:Videomanagement,
path:"/Videomanagement"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Multipageforms2:{
 component:Multipageforms2,
path:"/Multipageforms2"},

  Home: {
component:Survey,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  
  
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <Provider store={store}>
         <PersistGate loading={null} persistor={persistor}>
      <View style={{ height: '100vh', width: '100%' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
      </PersistGate>
      </Provider>
    );
  }
}

export default App;