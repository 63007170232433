export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_EMAIL = 'SET_EMAIL';
export const SET_SHOW_BACK_BUTTON = 'SET_SHOW_BACK_BUTTON';
export const SET_INITIAL_QUESTIONS = 'SET_INITIAL_QUESTIONS';
export const SET_STEP = 'SET_STEP';

export const SET_SKIPPED_QUESTION_IDS = "SET_SKIPPED_QUESTIONS_IDS";
const INITIAL_STATE = {
  questions: [],
  email: '',
  showBackButton: true,
  reduxStep: 0,
  skippedQuestionsIds: []
};

const QuestionReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {

    case SET_INITIAL_QUESTIONS:

      let newArray: any = action.payload;
      state.questions.forEach((ele: any) => {
        if (ele.attributes.response_type === "single_choice" || ele.attributes.response_type === "body_type") {
          let questionIndex = newArray.findIndex((question: any) => question.attributes.question_id === ele.attributes.question_id);
          if (questionIndex >= 0) {
            let selectedOpt = ele.attributes.options.find((_ele: any) => _ele.is_selected);
            newArray[questionIndex].attributes.options = newArray[questionIndex].attributes.options.map((ele2: any) => {
              if (selectedOpt && selectedOpt.id === ele2.id) {
                return { ...ele2, is_selected: true }
              } else {
                return ele2
              }
            })
          }
        } else if (ele.attributes.response_type === "multiple_choice" || ele.attributes.response_type === "meal_type") {
          let questionIndex = newArray.findIndex((question: any) => question.attributes.question_id === ele.attributes.question_id);
          if (questionIndex >= 0) {
            let selectedOptions = ele.attributes.options.filter((_ele: any) => _ele.is_selected);
            console.log(selectedOptions, ">>>>>>>>>>>>>>>>>selectedOptions")

            newArray[questionIndex].attributes.options = newArray[questionIndex].attributes.options.map((ele2: any) => {
              let selectedOption = selectedOptions.find((element: any) => element.id === ele2.id)
              if (selectedOption) {
                return { ...selectedOption }
              } else {
                return ele2
              }
            })
          }
        }
        else if (ele.attributes.response_type === "subjective") {
          let questionIndex = newArray.findIndex((question: any) => question.attributes.question_id === ele.attributes.question_id);
          if (questionIndex >= 0) {
            newArray[questionIndex].attributes.options[0] = ele.attributes.options[0]
          }
        }
      })
      return {
        ...state,
        questions: newArray,
      };

    case SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      }
    case SET_SHOW_BACK_BUTTON:
      return {
        ...state,
        showBackButton: action.payload,
      }
    case SET_STEP:
      return {
        ...state,
        reduxStep: action.payload,
      }
    case SET_SKIPPED_QUESTION_IDS:
      console.log(action.payload);
      return {
        ...state,
        skippedQuestionsIds: action.payload,
      }
    default:
      return state;
  }
};

export default QuestionReducer

