import React from "react";
import { GreetStaticScreen } from "./GreetStaticScreen.web";
import SingleSelectForm from "./SingleSelectForm.web";
import MultiSelectFrom from "./MultiSelectFrom.web";
import { ResponseType } from "./types";
import SubjectiveForm from "../../cfneededforunitconversion5/src/SubjectiveForm.web";
import EmailFormView from "../../email-account-registration/src/EmailFormView.web";
import BodyType from "./BodyType.web";
import { WeightGraph } from "./WeightGraph.web";
import DataVisualization from "./DataVisualization.web";
import MealList from "./MealList.web";
import { GoalIllustration } from "./GoalIllustration.web";

interface Props {
  step: number;
  questions: any[];
  responceType: string;
  updateQuestionsAction: (questions: any[]) => void;
  updateSkipppedQuestionIdsAction: (questions: any[]) => void;
  setStepFunc: (step: number) => void;

  skippedQuestions:any,
  target_month: any;
  target_weight: string;
  weight_loss_data: any,
  weight_loss_month: any,
  weight_unit:string
}

const MultiPageFormHelper: React.FC<Props> = (props) => {
  const {
    questions,
    step,
    responceType,
    updateQuestionsAction,
    updateSkipppedQuestionIdsAction,
    setStepFunc,
    target_month,
    skippedQuestions,
    target_weight,
    weight_loss_data,
    weight_loss_month,
    weight_unit
  } = props;
  if (!questions[step]) {
    return <>Invalid Question</>;
  }

  const { attributes } = questions[step];

  switch (responceType) {
    case "static":
      return (

        <GreetStaticScreen
          greeting={attributes.question}
          setStepFunc={setStepFunc}
          questions={questions}
          step={step}
          visual={attributes}
          updateSkipppedQuestionIdsAction={updateSkipppedQuestionIdsAction}
          skippedQuestions={skippedQuestions}
        />
      );
    case "single_choice":
      return (

        <SingleSelectForm
          questions={questions}
          step={step}
          attributes={attributes}
          updateQuestionsAction={updateQuestionsAction}
          setStepFunc={setStepFunc}
        />

      );
    case "weight_graph":
      return (

        <WeightGraph
          description={attributes.description}
          questions={questions}
          greeting={attributes.question}
          setStepFunc={setStepFunc}
          target_month={target_month}
          target_weight={target_weight}
          weight_loss_month={weight_loss_month}
          weight_loss_data={weight_loss_data}
          weight_unit={weight_unit}
        />

      );
    case "body_type":
      return (

        <BodyType
          questions={questions}
          step={step}
          attributes={attributes}
          updateQuestionsAction={updateQuestionsAction}
          setStepFunc={setStepFunc}
        />
      );
      case "meal_type":
      return (

        <MealList 
          questions={questions}
          step={step}
          attributes={attributes}
          setStepFunc={setStepFunc}
          updateQuestionsAction={updateQuestionsAction}
/>
      );
      case "last_image":
      return (

   <GoalIllustration 
   greeting={attributes.question}
          setStepFunc={setStepFunc}
          questions={questions}
          step={step}
          visual={attributes}
         
   
   />
      );
    case "multiple_choice":
      return (
        <MultiSelectFrom
          questions={questions}
          step={step}
          attributes={attributes}
          setStepFunc={setStepFunc}
          updateQuestionsAction={updateQuestionsAction}
        />
      );
    case "data_visualization":
      return (
        <DataVisualization
          questions={questions}
          attributes={attributes}
          setStepFunc={setStepFunc}
        />
      );
    case ResponseType.SUBJECIVE:
      return <SubjectiveForm
        attributes={attributes}
        step={step}
        setStepFunc={setStepFunc}
        questions={questions}
        updateQuestionsAction={updateQuestionsAction}
      />;
    case ResponseType.EMAIL:
      return <EmailFormView step={step} setStepFunc={setStepFunc} attributes={attributes} />
    default:
      return <>Invalid Qestions</>;
  }
};

export default MultiPageFormHelper;
